<template>
  <div class="article">
    <div class="actionBar">
      <a-space>
        <CompanySelect
          @CompanySelect="CompanySelect"
          :serchKeyword="undefined"
          :selectId="listParams.company_id"
          style="width:200px"
        ></CompanySelect>
        <GroupSelect @GroupSelect="GroupSelect"></GroupSelect>
        <a-range-picker
          style="width: 280px"
          v-model="time"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :locale="locale"
          @change="timeChange"
        >
        </a-range-picker>
        <a-input-search
          v-model="listParams.keyword"
          placeholder="请输入关键词搜索"
          enter-button="查询"
          style="width: 220px"
          allowClear
          @search="onSearch"
        />
      </a-space>

      <a-space>
        <a-button
          v-if="handLists.top.indexOf('add') != -1"
          icon="plus"
          type="primary"
          @click="addLive"
        >
          新增</a-button
        >
      </a-space>
    </div>

    <a-modal v-model="visible2" :footer="null" @cancel="outVideo" :width="960">
      <video
        :src="videoUrl"
        controls
        autoplay
        style="max-width:880px;min-height:400px"
      ></video>
    </a-modal>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="img_url" slot-scope="text">
        <a v-if="text" @click="pvwImg(text)">
          <img :src="text" alt="" style="maxWidth:100px;maxHeight:100px" />
        </a>
        <span v-else>
          暂无
        </span>
      </span>
      <span slot="video_url" slot-scope="text">
        <span>
          <a-icon
            title="点击查看"
            type="video-camera"
            class="videoCamera"
            @click="videoPev(text)"
          />
        </span>
      </span>
      <span slot="action" slot-scope="text, record">
        <span v-if="handLists.right.indexOf('edit') != -1">
          <FontIcon
            @click.native="editMenu(record)"
            title="修改"
            type="icon-xiugai"
            size="22"
          ></FontIcon>
        </span>

        <span v-if="handLists.right.indexOf('del') != -1">
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定删除？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm(record)"
          >
            <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
          </a-popconfirm>
        </span>
      </span>
    </a-table>
  </div>
</template>

<script>
import { meetIndex, meetDelete } from "@/apis/index";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "标题",
    dataIndex: "title",
    scopedSlots: { customRender: "titles" },
  },
  {
    title: "会议时间",
    dataIndex: "start_time",
    scopedSlots: { customRender: "start_time" },
    customRender: (text, record) => {
      if (text > 0) {
        let start_time = moment(Number(text + "000")).format(
          "YYYY/MM/DD HH:mm:ss"
        );
        let end_time = moment(Number(record.end_time + "000")).format(
          "YYYY/MM/DD HH:mm:ss"
        );
        return `${start_time} 至 ${end_time}`;
      } else {
        return "";
      }
    },
  },

  // {
  //   title: "开始时间",
  //   dataIndex: "start_time",
  //   scopedSlots: { customRender: "start_time" },
  //   customRender: (text, record) => {
  //     if (text > 0) {
  //       return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
  //     } else {
  //       return "";
  //     }
  //   },
  // },
  // {
  //   title: "结束时间",
  //   dataIndex: "end_time",
  //   scopedSlots: { customRender: "end_time" },
  //   customRender: (text, record) => {
  //     if (text > 0) {
  //       return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
  //     } else {
  //       return "";
  //     }
  //   },
  // },
  {
    title: "栏目名称",
    dataIndex: "group",
    scopedSlots: { customRender: "group" },
    customRender: (text) => {
      if (text) {
        return text.name;
      } else {
        return "";
      }
    },
  },
  {
    title: "企业名称",
    dataIndex: "company",
    scopedSlots: { customRender: "company" },
    customRender: (text) => {
      if (text) {
        return text.company_name;
      } else {
        return "";
      }
    },
  },

  {
    align: "center",
    title: "视频查看",
    dataIndex: "video_url",
    scopedSlots: { customRender: "video_url" },
    width: 90,
  },
  {
    title: "封面图",
    dataIndex: "img_url",
    scopedSlots: { customRender: "img_url" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 120,
  },
];
export default {
  data() {
    return {
      locale,
      columns,
      data: [],
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
        group_id: undefined,
        start_time: "",
        end_time: "",
        company_id: undefined,
        live_source: "0",
      },
      count: 0,
      time: [],
      handLists: this.$store.state.menuHandleList,

      visible2: false,
      videoUrl: "",
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists.top = val.top ? val.top : [];
      this.handLists.right = val.right ? val.right : [];
    },
  },
  created() {
    this.getMeetIndex();
  },
  methods: {
    confirm(rec) {
      meetDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getLivePlayIndex();
        }
      });
    },

    editMenu(rec) {
      this.$router.push({
        path: "/Live/AddEditMeet",
        query: { id: rec.id },
      });
    },
    addLive() {
      this.$router.push({
        path: "/Live/AddEditMeet",
      });
    },
    outVideo() {
      this.videoUrl = "";
    },
    videoPev(url) {
      this.visible2 = true;
      this.videoUrl = url;
    },

    CompanySelect(val) {
      this.listParams.company_id = val;
      this.getLivePlayIndex();
    },
    timeChange(a) {
      if (a.length > 0) {
        this.listParams.start_time = a[0];
        this.listParams.end_time = a[1];
      } else {
        this.listParams.start_time = "";
        this.listParams.end_time = "";
      }
      this.getMeetIndex();
    },

    GroupSelect(val) {
      this.listParams.group_id = val;
      this.getMeetIndex();
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getMeetIndex();
    },
    getMeetIndex() {
      meetIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getMeetIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch() {
      this.listParams.page = 1;
      this.getMeetIndex();
    },
    // statuChange(){
    //   this.listParams.page=1
    //   this.getLivePlayIndex();
    // },
  },
};
</script>

<style lang="less" scoped>
.article {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
  .videoCamera {
    font-size: 20px;
  }
  .videoCamera:hover {
    font-size: 30px;
    transition: all 0.3s;
    color: rgb(88, 116, 199);
  }
}
</style>
